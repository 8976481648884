import React from "react";
import Layout from "../Layout";
import Title from './../Title';
import { css } from 'emotion';
// import BackButton from "../layout/back_button";

const main = css({

});

const BaseForm = (props) => {
  return (
    <Layout>
      <section className={main}>
        <div className="content">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <Title title={props.title} />

              {/* <form ref={this.formRef} className="contact-input" onSubmit={this.handleSubmit.bind(this)}>

              </form> */}

              <div className="box">{props.children}</div>
            </div>
          </div>
        </div>
      </section>

    </Layout>

  )
}

export default BaseForm