import React, { useState, useEffect } from "react";
import { Form, TextArea, Text, Checkbox, useFormApi } from 'informed';
import { css } from 'emotion';

import firestore from '../../utils/firestore';

import BaseForm from './base_form';
import Editor from './editor';
// import RichTextExample from './editor';

const main = css({
  '.modal-card-head': {
    backgroundColor: '#C7EBBC',
    paddingBottom: '0'
  },

  'input[type=checkbox]': {
    marginLeft: '5px'
  },

  '.control': {
    '&.submit': {
      textAlign: 'center !important'
    },

    '&.submit button[type="submit"]': {
      color: '#fff',
      padding: '0 4em'
    }
  },
});
const BlogForm = () => {

  // const formApi = useFormApi();
  let [formApi, setFormApiValue] = useState(null);
  // let [fileError, setUploadError] = useState(false);
  // let [photoFile, setPhotoFile] = useState(null);
  // let [photo, setPhoto] = useState(null);
  let [isModalOpen, setIsModalOpen] = useState(false);
  let [submittedSuccess, setSubmittedSuccess] = useState(false);
  let [loading, setLoading] = useState(false);
  let [clearContent, setClearContent] = useState(false);

  const setFormApi = (formApi) => {
    setFormApiValue(formApi);
  }

  const clearForm = () => {
    formApi.reset();
    setClearContent(true);
    setLoading(false);
    if (typeof window !== "undefined") localStorage.removeItem('blog-editor-content');
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const submitForm = async (e) => {

    try {
      setLoading(true);
      writeToFirestore(formApi.getState().values);

    } catch (err) {
      console.log({ err });
    }

  }
  const signUserIn = () => {
    const user = firestore.auth.currentUser;
    if (!user) {
      return firestore.auth.signInAnonymously();
    } else {
      return new Promise((resolve, reject) => resolve(true));
    }
  }

  const writeToFirestore = (data) => {
    signUserIn()
      .then(() => {
        firestore.store.collection('blog_entry').add(data)
          .then(res => {
            setSubmittedSuccess(true);
            setIsModalOpen(true);
            setLoading(false);
          })
          .catch(err => {
            setSubmittedSuccess(false);
            setIsModalOpen(true);
            setLoading(false);
          });
      })
      .catch(err => console.log(`Error signing in ${err}`))
  }

  const validateRequired = (value) =>
    value !== undefined
      && value.length > 0 ? undefined : `Ingrese un texto`;

  const validateRequiredOptional = (value) => {
    return formApi.getValue('anonymous') || formApi.getValue('anonymous') !== undefined ? undefined : value !== undefined
      && value.length > 0 ? undefined : `Ingrese un texto`;
  }

  const onAnonChange = (value) => {
    if (value.target.checked) {
      formApi.setValue('author', 'Anónima');
      formApi.setValue('bio', 'Anónima');
      formApi.setValue('photoURL', '');
    }
  }

  // const handleFiles = files => {
  //   for (var i = 0; i < files.length; i++) {
  //     if (files[i].size <= 300000 && isFileImage(files[i])) {
  //       setUploadError('');
  //       setPhoto(URL.createObjectURL(files[i]));
  //       setPhotoFile(files[i]);
  //     } else {
  //       setUploadError('El archivo debe ser una imagen y no puede superar los 300kb');
  //     }
  //   }
  // };

  // const isFileImage = (file) => file && file['type'].split('/')[0] === 'image';

  const closeModal = (e) => {
    e.preventDefault();

    setIsModalOpen(false);

    if (submittedSuccess)
      clearForm();
  }

  const getSubmitText = () =>
    <div>
      <p>Estimada: {formApi.getValue('author')}</p>
      <p>Vamos a revisar el contenido que has ingresado para incluir una entrada en el blog, prontamente te estaremos avisando si ha sido aprobado para su publicación.</p>
      <p>Nuestra revisión se centra en que el texto enviado no violente ninguna población, partimos de la libertad de expresión con enfoque feminista: esto quiere decir que existen opiniones que son violentas y no las acuerpamos ni las reproducimos.</p>
      <p>Te avisaremos cuando se publique. Gracias por publicar en nuestro blog.</p>
      <p className="has-text-right has-text-weight-semibold">~ Chicas al Frente</p>
    </div>

  const getSerializedContent = (value) => {
    console.log('getSerializedContent')
    setClearContent(false);
    formApi.setValue('content', value);    
  }

  return (

    <BaseForm title={"Participa en el Blog"} description=''>
      <Form className={main} getApi={setFormApi} onSubmit={submitForm}>
        {({ formState }) => (
          <>
            <p className="has-text-justified">
              En Chicas al Frente tenemos un espacio para publicar la creatividad de tus ideas, ya sea una vivencia, una poesía, prosa, denuncia o anunciar un evento.
              ¿Qué necesitamos?
            </p>
            <hr />

            <div className="field">
              <label className={`label ${formState.errors["title"] !== undefined && 'has-text-danger'}`}>Titulo</label>
              <div className="control" >
                <Text name="title" type="text" field="title" className={`input ${formState.errors["title"] !== undefined && 'is-danger'}`} validate={validateRequired} validateOnChange />
              </div>
              <span className="help">Título del texto que vas a incluir</span>
            </div>

            <div className="field">
              <label className={`label ${formState.errors["description"] !== undefined && 'has-text-danger'}`}>Descripción corta</label>
              <div className="control" >
                <TextArea name="description" rows="2" type="text" field="description" className={`textarea ${formState.errors["description"] !== undefined && 'is-danger'}`} validate={validateRequired} validateOnChange />
                <span className="help">Resumen del texto que vas a incluir</span>
              </div>
            </div>

            <div className="field" hidden>
              <label className={`label ${formState.errors["content"] !== undefined && 'has-text-danger'}`}>Texto</label>
              <div className="control" >
                <TextArea name="content" rows="2" type="text" field="content" className={`textarea ${formState.errors["content"] !== undefined && 'is-danger'}`} validate={validateRequired} validateOnChange />
                <span className="help">Escribe acá lo quieras publicar</span>
              </div>
            </div>

            <div className="field">
              <label className={`label ${formState.errors["content"] !== undefined && 'has-text-danger'}`}>Texto</label>
              <div className="control">
                <Editor setEditorContent={getSerializedContent} clearContent={clearContent} />
                <span className="help">Escribe acá lo quieras publicar</span>
              </div>
            </div>

            <div className="field">
              <label className={`label ${formState.errors["contentImageURL"] !== undefined && 'has-text-danger'}`}>URL imagen (opcional)</label>
              <div className="control" >
                <Text name="contentImageURL" type="text" name="" field="contentImageURL" className='input' />
                <span className="help">Una imagen que acompañe el texto (si no tienes una imagen, podremos una imagen de referencia)</span>
              </div>
            </div>

            <div className="field">
              <label className="label is-inline">Anónima</label>
              <div className="control is-inline" >
                <Checkbox name="anonymous" type="checkbox" field="anonymous" className="checkbox" onChange={onAnonChange} />
                <span className="help">Selecciona si deseas que el texto se publique sin tu nombre</span>
              </div>
            </div>

            <div className="field" hidden={formState.values["anonymous"]}>
              <label className={`label ${formState.errors["author"] !== undefined && 'has-text-danger'}`}>Autora</label>
              <div className="control" >
                <Text name="author" field="author" className={`input ${formState.errors["author"] !== undefined && 'is-danger'}`} validate={validateRequiredOptional} validateOnChange />
                <span className="help">Escribe tu nombre, pseudónimo, o a nombre de quien quieres que se muestre</span>
              </div>
            </div>

            {/* <div className="field" hidden={formState.values["anonima"]}>
              <label className="label">Fotografía</label>
              <input type="file" className="file-upload" accept="image/*" style={{ outline: 'none' }} onChange={(e) => handleFiles(e.target.files)} />
              <div hidden={!fileError}>
                <p className='help is-danger'>{fileError}</p>
              </div>
              {photo &&
                <figure className="image is-128x128">
                  <img src={photo} alt="fotografia" />
                </figure>}
            </div> */}

            <div className="field" hidden={formState.values["anonymous"]}>
              <label className={`label ${formState.errors["photoURL"] !== undefined && 'has-text-danger'}`}>URL Fotografía (opcional)</label>
              <div className="control" >
                <Text name="photoURL" type="text" name="" field="photoURL" className='input' />
                <span className="help">Una foto de la autora (si no envías una foto se coloca el logo de Chicas al Frente)</span>
              </div>
            </div>

            <div className="field" hidden={formState.values["anonymous"]}>
              <label className={`label ${formState.errors["bio"] !== undefined && 'has-text-danger'}`}>Biografía (opcional)</label>
              <div className="control">
                <TextArea type="text" field="bio" className={`textarea ${formState.errors["bio"] !== undefined && 'is-danger'}`} />
                <span className="help">Escribe algo sobre vos</span>
              </div>
            </div>
            {/* {JSON.stringify(formState.errors)} */}
            <div className="content">
              <p className="has-text-centered">
                En Chicas al Frente tu voz tiene importancia y tiene un espacio: anímate, escribí <span role="img">💜</span>
              </p>
            </div>

            <div className="control submit">
              <button type="submit" className={`button is-primary ${loading && 'is-loading'}`}>Enviar</button>
              <br />
              <div hidden={!Object.keys(formState.errors).length}>
                <p className={`help is-danger has-text-centered`}>Existen errores en el formulario</p>
              </div>
              <br />
              <button className="button is-small" type="button" onClick={() => clearForm()}>Cancelar</button>
            </div>

            <div className={`modal ${isModalOpen && 'is-active'}`}>
              <div className="modal-background"></div>
              <div className="modal-card">
                <header className="modal-card-head">
                  <p className="modal-card-title has-text-centered">{submittedSuccess ? 'Información enviada con éxito' : 'Ocurrió un error'}</p>
                  <button className="delete" aria-label="close-right" onClick={closeModal}></button>
                </header>
                <section className="modal-card-body">
                  {
                    submittedSuccess ? getSubmitText() :
                      'No se ha podido enviar el formulario. Intentá de nuevo más tarde.'
                  }
                </section>
              </div>
            </div>
          </>
        )}
      </Form>

      {/* <Notification message={snowRes} error={hasError} /> */}

    </BaseForm>
  )

}

export default BlogForm