import React, { useCallback, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import isUrl from 'is-url'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import { Editor, Transforms, createEditor, Range, Point } from 'slate'
import { withHistory } from 'slate-history'
import { css } from 'emotion'
import { Text } from 'slate'
import escapeHtml from 'escape-html'

import { Button, Icon, Toolbar } from './components'

const main = css({
  border: '1px solid #ccc',
  borderRadius: '5px',

  'div:nth-child(2)': {
    minHeight: '200px'
  }
});

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  // 'mod+u': 'underline',
  // 'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const getIntialValue = () => {
  // if (typeof window !== "undefined" && "blog-editor-content" in localStorage)
  //   return JSON.parse(localStorage.getItem('blog-editor-content'))
  // else
  return [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ]
}

const RichTextExample = ({ setEditorContent, clearContent }) => {
  const [value, setValue] = useState(getIntialValue())
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(
    () => withLinks(withHistory(withReact(createEditor()))),
    []
  )

  return (
    <div className={main}>
      <Slate
        editor={editor}
        value={!clearContent ? value : getIntialValue()}
        onChange={value => {
          setValue(value)

          const content = JSON.stringify(value)
          if (typeof window !== "undefined") localStorage.setItem('blog-editor-content', content)
          setEditorContent(serialize(editor))
          // setEditorContent(clearContent ? Plain.deserialize('') : serialize(editor))
        }}>
        <Toolbar>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          {/* <MarkButton format="link" icon="format_link" /> */}
          {/* <MarkButton format="underline" icon="format_underlined" /> */}
          {/* <MarkButton format="code" icon="code" /> */}
          <LinkButton format="link" />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
        </Toolbar>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Escribe tu texto aquí..."
          spellCheck
          autoFocus
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
        />
      </Slate>
    </div>
  )
}

const serialize = node => {
  if (Text.isText(node)) {
    return node.text
  }

  const children = node.children.map(n => serialize(n)).join('');

  switch (node.type) {
    case 'link':
      return `[${children}](${node.url.toLowerCase().startsWith('http') ? escapeHtml(node.url) : escapeHtml(`https://${node.url}`)})`
    case 'paragraph':
      return node.children.map(child =>
        child.italic ? `*${child.text}*` :
          child.bold ? `**${child.text}**` :
            child.url ? `[${child.children[0].text}](${child.url.toLowerCase().startsWith('http') ? escapeHtml(child.url) : escapeHtml(`https://${child.url}`)})` :
              child.text
      ).join('').concat('\\n')
    case 'code':
      return `\`${children}\``
    case 'heading-one':
      return `# ${children}\\n`
    case 'heading-two':
      return `## ${children}\\n`
    case 'block-quote':
      return `\\n> ${children}\\n`
    case 'numbered-list':
      return node.children.map((child, i) => `${i + 1}. ${child.children[0].text}\\n`).join('').concat('\\n')
    case 'bulleted-list':
      return node.children.map(child => `- ${child.children[0].text}\\n`).join('').concat('\\n')
    default:
      return children
  }

}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  // const [match] = Editor.nodes(editor, {
  //   at: range,
  //   match: n => n.type === format,
  // });

  const match = editor.children.find(child => child.type === format)

  return match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)

  return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'link':
      return (
        <a {...attributes} href={element.url}>
          {children}
        </a>
      )
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.link) {
    children = <a href={leaf.text} target="_blank" rel="noopener noreferrer">{children}</a>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const LinkButton = () => {
  const editor = useSlate()
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt('Ingrese el URL del enlace:')
        if (!url) return
        insertLink(editor, url)
      }}
    >
      <Icon>link</Icon>
    </Button>
  )
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' })
}

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, { match: n => n.type === 'link' })
  return !!link
}

const withLinks = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const initialValue = [
  {
    type: 'paragraph',
    children: [
      { text: 'This is editable ' },
      { text: 'rich', bold: true },
      { text: ' text, ' },
      { text: 'much', italic: true },
      { text: ' better than a ' },
      { text: '<textarea>', code: true },
      { text: '!' },
    ],
  },
  {
    type: 'paragraph',
    children: [
      {
        text:
          "Since it's rich text, you can do things like turn a selection of text ",
      },
      { text: 'bold', bold: true },
      {
        text:
          ', or add a semantically rendered block quote in the middle of the page, like this:',
      },
    ],
  },
  {
    type: 'block-quote',
    children: [{ text: 'A wise quote.' }],
  },
  {
    type: 'paragraph',
    children: [{ text: 'Try it out for yourself!' }],
  },
]

export default RichTextExample