import React from 'react'
import { css } from 'emotion';

import BlogForm from '../../components/forms/blog_form';

const main = css({
  '.content': {
    padding: '2em 0'
  }
});

const BlogRegistro = () => (
  <div className={`content ${main}`}>
    <BlogForm />
  </div>
)

export default BlogRegistro
