import React from 'react'
import { css } from 'emotion'

const main = css({
  marginBottom: '0.5em',
  h1: {
    fontWeight: 'bold',
    color: '#000',
    lineHeight: '.5 !important'
  },
  h3: {
    fontWeight: 'bold !important',
    color: '#000 !important',
    marginBottom: '2rem'
  },
  'h1 span': {
    fontSize: '2rem',
    padding: '0.2em',
    textDecoration: 'underline solid #73CE57',
    textDecorationSkipInk: 'none',
    textUnderlinePosition: 'under'
  },
  '.title': {
    marginLeft: '4px',
    lineHeight: 1.55
  },

  '@media all and (max-width: 450px)': {
    '.content': {
      padding: '1em 2em !important',
      boxShadow: '-4px -4px 0px 0px #C7EBBC',
      marginLeft: '2px'
    },
    'h1 span': {
      fontSize: '1.4rem',
    },
    '.title': {
      marginLeft: '4px',
    }
  }
})

const Title = ({ title, subtitle }) => (
  <section className={main}>
    <div className="title">
      <h1 className="has-text-centered"><span>{title ? title.toUpperCase() : ''}</span></h1>
      <h3 className="subtitle is-6 is-spaced has-text-centered">{subtitle || ''}</h3>
    </div>
  </section>
)

export default Title;